// Styles
import { useState } from 'react';
import './CustomisedForm.scss';

export default function CustomisedForm() {
	// useState hook
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [company, setCompany] = useState('');
	const [design, setDesign] = useState();
	const [extraDetails, setExtraDetails] = useState('');

	return (
		<div className='container'>
			<div className='customised-form-container'>
				<div className='text-wrapper'>
					<h2>Fill this form</h2>
					<p>
						The company was established in 2000 as Sri Lanka’s pioneer
						manufacturer of steel wardrobes and tables.
					</p>
				</div>

				<form action=''>
					<div className='form-wrapper'>
						<div className='input-wrapper'>
							<input
								type='text'
								id='fname'
								name='fname'
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<label htmlFor='fname'>First name</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='email'
								name='email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<label htmlFor='email'>Email Address</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='contact'
								name='contact'
								required
								value={contact}
								onChange={(e) => setContact(e.target.value)}
							/>
							<label htmlFor='contact'>Contact Number</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='cname'
								name='cname'
								required
								value={company}
								onChange={(e) => setCompany(e.target.value)}
							/>
							<label htmlFor='cname'>Company Name</label>
						</div>

						{/* Upload input */}
						<div className='input-wrapper'>
							<label>Upload Your Design</label>
							<input
								type='file'
								id='myfile'
								name='myfile'
								value={design}
							/>
							<label className='upload-container' htmlFor='myfile'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='49'
									height='48'
									viewBox='0 0 49 48'
									fill='none'
								>
									<path
										d='M16.5 32L24.5 24M24.5 24L32.5 32M24.5 24V42M40.5 33.4857C42.943 31.4681 44.5 28.4159 44.5 25C44.5 18.9249 39.5751 14 33.5 14C33.063 14 32.6541 13.772 32.4322 13.3955C29.8241 8.96967 25.0089 6 19.5 6C11.2157 6 4.5 12.7157 4.5 21C4.5 25.1322 6.17089 28.8742 8.8739 31.5871'
										stroke='#1D2670'
										stroke-width='4'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</label>
						</div>

						{/* Text area */}
						<div className='input-wrapper extra-details-wrapper'>
							<textarea
								id='edetails'
								name='edetails'
								rows='4'
								cols='50'
								value={extraDetails}
								onChange={(e) => setExtraDetails(e.target.value)}
							></textarea>
							<label htmlFor='edetails'>Extra Details</label>
						</div>
					</div>

					<div className='input-wrapper'>
						<input
							className='btn-primary submit-btn'
							type='submit'
							value='Submit'
						/>
					</div>
				</form>
			</div>
		</div>
	);
}
