import { Canvas } from '@react-three/fiber';
import { useGLTF, Stage, PresentationControls } from '@react-three/drei';

export default function ThreeDModel(productThreeD) {
	function Model(props) {
		const { scene } = useGLTF(`${productThreeD.productThreeD}`);
		return <primitive object={scene} {...props} />;
	}

	return (
		<Canvas dpr={[1, 2]} camera={{ fov: 45 }}>
			<color attach='background' args={['#F5F6F9']} />
			<PresentationControls
				speed={1.5}
				global
				zoom={0.5}
				polar={[-0.1, Math.PI / 4]}
			>
				<Stage intensity={Math.PI / 230} shadows={null} environment={null}>
					<Model scale={0.01} />
				</Stage>
			</PresentationControls>
		</Canvas>
	);
}
