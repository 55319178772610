import { useState } from 'react';

// ProductCart styles
import './ProductCart.scss';

// Components
import ThreeDView from '../three-d-view/ThreeDView';
import ImgView from '../img-view/ImgView';

export default function ProductCart({
	children,
	name,
	desc,
	prodCode,
	productId,
	productId2,
	productId3,
	productThreeD,
	dimentions
}) {
	// useState hook
	const [btn3dActive, setBtn3dActive] = useState(false);
	const [btnImgViewActive, setBtnImgViewActive] = useState(false);

	return (
		<div className='col-md-4'>
			<div className='product-cart'>
				{/* 3D view */}
				{/* <button className='btn-3d' onClick={() => setBtn3dActive(true)}>
					View in 3D
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
					>
						<path
							d='M10.0001 7.5L3.38663 11.7987C2.81343 12.1713 2.52682 12.3576 2.42756 12.5939C2.34081 12.8003 2.34081 13.033 2.42756 13.2395M10.0001 7.5L16.6135 11.7987C17.1867 12.1713 17.4733 12.3576 17.5726 12.5939C17.6594 12.8003 17.6594 13.033 17.5726 13.2395M10.0001 7.5V2.08333M10.0001 12.5L3.38663 8.20126C2.81343 7.82868 2.52682 7.64238 2.42756 7.40614C2.34081 7.19968 2.34081 6.96699 2.42756 6.76053M10.0001 12.5L16.6135 8.20126C17.1867 7.82868 17.4733 7.64238 17.5726 7.40614C17.6594 7.19968 17.6594 6.96699 17.5726 6.76053M10.0001 12.5V17.9167M17.7267 13.311L10.7267 17.861C10.4637 18.032 10.3322 18.1175 10.1905 18.1507C10.0653 18.1801 9.9349 18.1801 9.80965 18.1507C9.66796 18.1175 9.53645 18.032 9.27343 17.861L2.27343 13.311C2.05176 13.1669 1.94092 13.0949 1.86062 12.9988C1.78954 12.9137 1.73614 12.8153 1.70356 12.7094C1.66675 12.5897 1.66675 12.4575 1.66675 12.1931V7.80692C1.66675 7.54253 1.66675 7.41034 1.70356 7.29064C1.73614 7.18468 1.78954 7.08628 1.86062 7.00122C1.94092 6.90512 2.05176 6.83308 2.27343 6.68899L9.27343 2.13899C9.53645 1.96803 9.66796 1.88255 9.80965 1.84929C9.9349 1.81989 10.0653 1.81989 10.1905 1.84929C10.3322 1.88255 10.4637 1.96803 10.7267 2.13899L17.7267 6.68899C17.9484 6.83308 18.0592 6.90512 18.1395 7.00122C18.2106 7.08628 18.264 7.18468 18.2966 7.29064C18.3334 7.41034 18.3334 7.54253 18.3334 7.80692V12.1931C18.3334 12.4575 18.3334 12.5897 18.2966 12.7094C18.264 12.8153 18.2106 12.9137 18.1395 12.9988C18.0592 13.0949 17.9484 13.1669 17.7267 13.311Z'
							stroke='black'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</button> */}
				{btn3dActive && (
					<ThreeDView
						name={name}
						productId={productId}
						productThreeD={productThreeD}
						setBtn3dActive={setBtn3dActive}
					/>
				)}

				{/* Image view */}
				<button
					className='btn-view-img'
					onClick={() => setBtnImgViewActive(true)}
				>
					View Images
				</button>
				{btnImgViewActive && (
					<ImgView
						name={name}
						desc={desc}
						prodCode={prodCode}
						dimentions={dimentions}
						productId={productId}
						productId2={productId2}
						productId3={productId3}
						setBtnImgViewActive={setBtnImgViewActive}
					/>
				)}

				{children}
			</div>

			<div className='name-wrapper'>
				<h6>{name} {prodCode && <span className='prod-code'>({prodCode})</span>}</h6>
			</div>
		</div>
	);
}
