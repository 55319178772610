import React,{useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';
// Styles
import './AboutUs.scss';

// Components
import NavBar from './../../components/navbar/NavBar';
import Header from './../../components/header/Header';
import Hero from './../../components/hero/Hero';
import Footer from './../../components/footer/Footer';
import CustomProduct from './../../components/custom-product/CustomProduct';
import Partners from './../../components/partners/Partners';

// Assets
import IMAGE_HEADER from './../../assets/images/about-us/img-header.png';
import IMAGE_WHO_WE_ARE from './../../assets/images/about-us/img-who-we-are.png';
import IMAGE_CHAIRMAN from './../../assets/images/about-us/directors/img-jagath-gunarathne.png';
import IMAGE_ONE from './../../assets/images/about-us/directors/img-1.png';
import IMAGE_TWO from './../../assets/images/about-us/directors/img-2.png';
import IMAGE_THREE from './../../assets/images/about-us/directors/img-3.png';
import IMAGE_FOUR from './../../assets/images/about-us/directors/img-4.png';

export default function AboutUs() {
	useEffect(() => {
		AOS.init();
	}, [])
	return (
		<div>
			<NavBar></NavBar>
			<Header bgImage={IMAGE_HEADER}>
				<Hero>
					<h1>
						About <br /> Milro Industries
					</h1>
				</Hero>
			</Header>

			<div className='aboutUs-section-one'>
				<div className='container'>
					<h2>Who we are</h2>
					<div className='text-wrapper'>
						<p>
							At Milro, we’re here to help you create a space that
							reflects you and what you love. We’ll put it all together
							for you. Free one-on-one design help, in our store or in
							your home!
						</p>
					</div>

					<div className='blue-box' data-aos="fade-up">
						<h2>
							30<span className='pluss'>+</span>
						</h2>
						<p>Business Partners</p>
						<h2>
							280<span className='pluss'>+</span>
						</h2>
						<p>Business Partners</p>
						<h2>
							1000<span className='pluss'>+</span>
						</h2>
						<p>Business Partners</p>
					</div>
				</div>
				<img src={IMAGE_WHO_WE_ARE} alt='' />
			</div>

			<div className='aboutUs-section-two'>
				<div className='container'>
					<h2>
						We are the best solution <br /> for your home
					</h2>

					<div className='row'>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='50'
									height='50'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M30.6667 13.3333H31.8251C39.9511 13.3333 44.0141 13.3333 45.5564 14.7927C46.8896 16.0542 47.4804 17.9127 47.1204 19.7125C46.704 21.7946 43.387 24.1409 36.7528 28.8333L25.9139 36.4998C19.2798 41.1923 15.9626 43.5385 15.5462 45.6207C15.1863 47.4205 15.7771 49.2789 17.1103 50.5405C18.6526 51.9999 22.7156 51.9999 30.8416 51.9999H33.3333M21.3333 13.3333C21.3333 17.7515 17.7516 21.3333 13.3333 21.3333C8.91507 21.3333 5.33334 17.7515 5.33334 13.3333C5.33334 8.91497 8.91507 5.33325 13.3333 5.33325C17.7516 5.33325 21.3333 8.91497 21.3333 13.3333ZM58.6667 50.6666C58.6667 55.0849 55.085 58.6666 50.6667 58.6666C46.2484 58.6666 42.6667 55.0849 42.6667 50.6666C42.6667 46.2483 46.2484 42.6666 50.6667 42.6666C55.085 42.6666 58.6667 46.2483 58.6667 50.6666Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='50'
									height='50'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M16 15.9999L28 27.9999M16 15.9999H8L5.33333 7.99992L8 5.33325L16 7.99992V15.9999ZM51.3573 7.30929L44.3503 14.3163C43.2943 15.3723 42.7663 15.9003 42.5684 16.5092C42.3944 17.0448 42.3944 17.6217 42.5684 18.1573C42.7663 18.7662 43.2943 19.2942 44.3503 20.3502L44.983 20.9829C46.0391 22.039 46.5671 22.567 47.176 22.7648C47.7115 22.9389 48.2885 22.9389 48.824 22.7648C49.4329 22.567 49.9609 22.039 51.017 20.9829L57.5714 14.4285C58.2774 16.1463 58.6667 18.0276 58.6667 19.9999C58.6667 28.1001 52.1002 34.6666 44 34.6666C43.0234 34.6666 42.0692 34.5711 41.146 34.3891C39.8496 34.1334 39.2014 34.0056 38.8085 34.0447C38.3907 34.0864 38.1849 34.149 37.8147 34.3471C37.4665 34.5334 37.1173 34.8827 36.4187 35.5812L17.3333 54.6666C15.1242 56.8757 11.5425 56.8757 9.33334 54.6666C7.1242 52.4574 7.1242 48.8757 9.33334 46.6665L28.4187 27.5812C29.1173 26.8827 29.4665 26.5334 29.6528 26.1852C29.8509 25.8151 29.9136 25.6092 29.9552 25.1914C29.9943 24.7985 29.8665 24.1503 29.6108 22.8539C29.4288 21.9307 29.3333 20.9765 29.3333 19.9999C29.3333 11.8997 35.8998 5.33325 44 5.33325C46.6813 5.33325 49.1946 6.05278 51.3573 7.30929ZM32.0001 39.9998L46.6667 54.6663C48.8758 56.8754 52.4575 56.8754 54.6666 54.6663C56.8758 52.4572 56.8758 48.8754 54.6666 46.6663L42.6008 34.6007C41.7467 34.5199 40.9139 34.3658 40.1088 34.1448C39.0713 33.86 37.9332 34.0667 37.1724 34.8275L32.0001 39.9998Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='50'
									height='50'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M56.5333 58.6666C57.2801 58.6666 57.6535 58.6666 57.9387 58.5213C58.1895 58.3934 58.3935 58.1895 58.5214 57.9386C58.6667 57.6534 58.6667 57.28 58.6667 56.5333V28.7999C58.6667 28.0532 58.6667 27.6798 58.5214 27.3946C58.3935 27.1437 58.1895 26.9397 57.9387 26.8119C57.6535 26.6666 57.2801 26.6666 56.5333 26.6666L50.1333 26.6666C49.3866 26.6666 49.0132 26.6666 48.728 26.8119C48.4771 26.9397 48.2732 27.1437 48.1453 27.3946C48 27.6798 48 28.0532 48 28.7999V35.1999C48 35.9467 48 36.32 47.8547 36.6052C47.7269 36.8561 47.5229 37.0601 47.272 37.1879C46.9868 37.3333 46.6134 37.3333 45.8667 37.3333H39.4667C38.7199 37.3333 38.3466 37.3333 38.0614 37.4786C37.8105 37.6064 37.6065 37.8104 37.4787 38.0613C37.3333 38.3465 37.3333 38.7199 37.3333 39.4666V45.8666C37.3333 46.6133 37.3333 46.9867 37.188 47.2719C37.0602 47.5228 36.8562 47.7268 36.6053 47.8546C36.3201 47.9999 35.9467 47.9999 35.2 47.9999H28.8C28.0533 47.9999 27.6799 47.9999 27.3947 48.1452C27.1438 48.2731 26.9398 48.477 26.812 48.7279C26.6667 49.0131 26.6667 49.3865 26.6667 50.1333V56.5333C26.6667 57.28 26.6667 57.6534 26.812 57.9386C26.9398 58.1895 27.1438 58.3934 27.3947 58.5213C27.6799 58.6666 28.0533 58.6666 28.8 58.6666L56.5333 58.6666Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M26.6667 18.1333C26.6667 17.3865 26.6667 17.0131 26.812 16.7279C26.9398 16.477 27.1438 16.2731 27.3947 16.1452C27.6799 15.9999 28.0533 15.9999 28.8 15.9999H35.2C35.9467 15.9999 36.3201 15.9999 36.6053 16.1452C36.8562 16.2731 37.0602 16.477 37.188 16.7279C37.3333 17.0131 37.3333 17.3865 37.3333 18.1333V24.5333C37.3333 25.28 37.3333 25.6534 37.188 25.9386C37.0602 26.1895 36.8562 26.3934 36.6053 26.5213C36.3201 26.6666 35.9467 26.6666 35.2 26.6666H28.8C28.0533 26.6666 27.6799 26.6666 27.3947 26.5213C27.1438 26.3934 26.9398 26.1895 26.812 25.9386C26.6667 25.6534 26.6667 25.28 26.6667 24.5333V18.1333Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M8.00001 34.1333C8.00001 33.3865 8.00001 33.0131 8.14533 32.7279C8.27317 32.477 8.47714 32.2731 8.72802 32.1452C9.01324 31.9999 9.38661 31.9999 10.1333 31.9999H16.5333C17.2801 31.9999 17.6534 31.9999 17.9387 32.1452C18.1895 32.2731 18.3935 32.477 18.5214 32.7279C18.6667 33.0131 18.6667 33.3865 18.6667 34.1333V40.5333C18.6667 41.28 18.6667 41.6534 18.5214 41.9386C18.3935 42.1895 18.1895 42.3934 17.9387 42.5213C17.6534 42.6666 17.2801 42.6666 16.5333 42.6666H10.1333C9.38661 42.6666 9.01324 42.6666 8.72802 42.5213C8.47714 42.3934 8.27317 42.1895 8.14533 41.9386C8.00001 41.6534 8.00001 41.28 8.00001 40.5333V34.1333Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M5.33334 7.46659C5.33334 6.71985 5.33334 6.34648 5.47867 6.06126C5.6065 5.81038 5.81047 5.60641 6.06136 5.47858C6.34657 5.33325 6.71994 5.33325 7.46668 5.33325H13.8667C14.6134 5.33325 14.9868 5.33325 15.272 5.47858C15.5229 5.60641 15.7269 5.81038 15.8547 6.06126C16 6.34648 16 6.71985 16 7.46659V13.8666C16 14.6133 16 14.9867 15.8547 15.2719C15.7269 15.5228 15.5229 15.7268 15.272 15.8546C14.9868 15.9999 14.6134 15.9999 13.8667 15.9999H7.46668C6.71994 15.9999 6.34657 15.9999 6.06136 15.8546C5.81047 15.7268 5.6065 15.5228 5.47867 15.2719C5.33334 14.9867 5.33334 14.6133 5.33334 13.8666V7.46659Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className='aboutUs-section-three'>
				<div className='text-wrapper'>
					<h2>
						WE WORKED IN ICONIC NATURAL SETTINGS, HISTORIC TOWNS, AND
						GROWING CITIES,ACROSS 6 CONTINENTS AND 30+ COUNTRIES
					</h2>
				</div>
			</div>

			<div className='aboutUs-section-partners'>
				<Partners />
			</div>

			<div className='aboutUs-section-custom-product'>
				<CustomProduct />
			</div>

			<div className='aboutUs-section-directors d-none'>
				<div className='container'>
					<h2>Board of Directors</h2>
					<div className='text-wrapper'>
						<p>
							Our Board of Directors is comprised of experienced and
							accomplished leaders who are passionate about our mission
							and vision.
						</p>
					</div>
					<div className='row chaieman-row'>
						<div className='col-md-4'>
							<div className='img-wrapper'>
								<img src={IMAGE_CHAIRMAN} alt='' />
							</div>
						</div>
						<div className='col-md-8 chairman-detail-container'>
							<div className='chairman-detail-wrapper'>
								<p className='chairman'>Chairman</p>
								<h5>Jagath Gunarathne</h5>
								<p>
									Lorem ipsum dolor sit amet consectetur. Donec tortor
									sollicitudin pharetra morbi eros aliquam. Eget nibh
									ante diam maecenas non sit scelerisque. Sit consequat
									cursus facilisi suspendisse. Leo erat eu odio nunc
									feugiat morbi eget viverra eu. Eget elementum quisque
									dignissim nec at. Dictumst neque nulla ullamcorper
									consectetur. Ultrices scelerisque nibh sodales netus
									etiam facilisis ut. At vestibulum in proin risus
									etiam arcu sit ut. Cum urna facilisis leo bibendum
									adipiscing tortor. Tincidunt nisl feugiat lacus
									congue. Vivamus bibendum aliquet ultrices ut eget
									viverra. In luctus ultrices sed volutpat diam dictum
									faucibus est. Enim morbi dui quis volutpat. Viverra a
									donec nibh dictumst.
								</p>
							</div>
						</div>
					</div>
					<div className='row team'>
						<div className='col-md-3'>
							<img src={IMAGE_ONE} alt='' />
							<h6>Sanath De Silva</h6>
						</div>
						<div className='col-md-3'>
							<img src={IMAGE_TWO} alt='' />
							<h6>Sanath De Silva</h6>
						</div>
						<div className='col-md-3'>
							<img src={IMAGE_THREE} alt='' />
							<h6>Sanath De Silva</h6>
						</div>
						<div className='col-md-3'>
							<img src={IMAGE_FOUR} alt='' />
							<h6>Sanath De Silva</h6>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}
