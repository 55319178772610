import React,{useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

import './CustomisedProducts.scss';

// Components
import NavBar from '../../components/navbar/NavBar';
import Header from './../../components/header/Header';
import Hero from './../../components/hero/Hero';
import Partners from './../../components/partners/Partners';
import CustomisedForm from '../../components/customised-form/CustomisedForm';
import Footer from './../../components/footer/Footer';

// Assets
import IMAGE_HEADER from './../../assets/images/customised-products/img-header.png';

export default function CustomisedProducts() {
	useEffect(() => {
		AOS.init();
	}, [])
	return (
		<div>
			<NavBar></NavBar>
			<Header bgImage={IMAGE_HEADER}>
				<Hero>
					<h1>
						customised <br /> products
					</h1>
				</Hero>
			</Header>

			<div className='customisedProducts-section-one'>
				<div className='container'>
					<h2>How it works</h2>
					<p>
						The company was established in 2000 as Sri Lanka’s pioneer{' '}
						<br /> manufacturer of steel wardrobes and tables.
					</p>
					<div className='row'>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='64'
									height='64'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M24.0001 29.9258L34.0742 39.9999M21.2664 55.9329C17.6215 59.5778 10.6668 58.6666 5.3335 58.6666C8.0671 53.3332 4.4222 46.3785 8.0671 42.7336C11.712 39.0887 17.6215 39.0887 21.2664 42.7336C24.9113 46.3785 24.9113 52.288 21.2664 55.9329ZM31.7911 42.4655L56.1567 16.1507C58.3028 13.8329 58.2336 10.2335 56 7.99989C53.7664 5.76631 50.167 5.69711 47.8492 7.8432L21.5344 32.2088C20.1746 33.4679 19.4946 34.0975 19.0981 34.7689C18.1472 36.3789 18.1089 38.3693 18.9973 40.0147C19.3677 40.7009 20.0229 41.3561 21.3334 42.6666C22.6438 43.977 23.299 44.6322 23.9852 45.0027C25.6306 45.891 27.621 45.8527 29.231 44.9018C29.9025 44.5053 30.532 43.8254 31.7911 42.4655Z'
										stroke='white'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6 >Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='64'
									height='64'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M16 15.9999L28 27.9999M16 15.9999H8.00004L5.33337 7.99992L8.00004 5.33325L16 7.99992V15.9999ZM51.3573 7.30929L44.3504 14.3163C43.2943 15.3723 42.7663 15.9003 42.5685 16.5092C42.3944 17.0448 42.3944 17.6217 42.5685 18.1573C42.7663 18.7662 43.2943 19.2942 44.3504 20.3502L44.9831 20.9829C46.0391 22.039 46.5671 22.567 47.176 22.7648C47.7116 22.9389 48.2885 22.9389 48.8241 22.7648C49.433 22.567 49.961 22.039 51.017 20.9829L57.5715 14.4285C58.2774 16.1463 58.6667 18.0276 58.6667 19.9999C58.6667 28.1001 52.1002 34.6666 44 34.6666C43.0235 34.6666 42.0692 34.5711 41.1461 34.3891C39.8497 34.1334 39.2015 34.0056 38.8085 34.0447C38.3908 34.0864 38.1849 34.149 37.8147 34.3471C37.4666 34.5334 37.1173 34.8827 36.4188 35.5812L17.3334 54.6666C15.1242 56.8757 11.5425 56.8757 9.33338 54.6666C7.12424 52.4574 7.12424 48.8757 9.33338 46.6665L28.4188 27.5812C29.1173 26.8827 29.4666 26.5334 29.6529 26.1852C29.851 25.8151 29.9136 25.6092 29.9552 25.1914C29.9944 24.7985 29.8666 24.1503 29.6109 22.8539C29.4288 21.9307 29.3334 20.9765 29.3334 19.9999C29.3334 11.8997 35.8999 5.33325 44 5.33325C46.6814 5.33325 49.1947 6.05278 51.3573 7.30929ZM32.0002 39.9998L46.6667 54.6663C48.8758 56.8754 52.4575 56.8754 54.6667 54.6663C56.8758 52.4572 56.8758 48.8754 54.6666 46.6663L42.6009 34.6007C41.7467 34.5199 40.9139 34.3658 40.1088 34.1448C39.0713 33.86 37.9332 34.0667 37.1725 34.8275L32.0002 39.9998Z'
										stroke='white'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
						<div className='col-md-4 cl' data-aos="fade-up">
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='64'
									height='64'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M37.3334 18.6667H43.5661C44.2183 18.6667 44.5444 18.6667 44.8513 18.7404C45.1234 18.8058 45.3835 18.9135 45.6221 19.0597C45.8912 19.2246 46.1218 19.4552 46.5831 19.9164L57.417 30.7504C57.8782 31.2116 58.1088 31.4422 58.2737 31.7113C58.42 31.9499 58.5277 32.21 58.593 32.4821C58.6667 32.789 58.6667 33.1151 58.6667 33.7674V41.3334C58.6667 42.5759 58.6667 43.1972 58.4637 43.6872C58.1931 44.3406 57.6739 44.8598 57.0205 45.1304C56.5305 45.3334 55.9092 45.3334 54.6667 45.3334M41.3334 45.3334H37.3334M37.3334 45.3334V19.2001C37.3334 16.2131 37.3334 14.7197 36.7521 13.5788C36.2408 12.5753 35.4249 11.7594 34.4213 11.248C33.2805 10.6667 31.787 10.6667 28.8 10.6667H13.8667C10.8798 10.6667 9.38629 10.6667 8.24542 11.248C7.24189 11.7594 6.426 12.5753 5.91467 13.5788C5.33337 14.7197 5.33337 16.2131 5.33337 19.2001V40.0001C5.33337 42.9456 7.72119 45.3334 10.6667 45.3334M37.3334 45.3334H26.6667M26.6667 45.3334C26.6667 49.7517 23.085 53.3334 18.6667 53.3334C14.2484 53.3334 10.6667 49.7517 10.6667 45.3334M26.6667 45.3334C26.6667 40.9151 23.085 37.3334 18.6667 37.3334C14.2484 37.3334 10.6667 40.9151 10.6667 45.3334M54.6667 46.6667C54.6667 50.3486 51.6819 53.3334 48 53.3334C44.3181 53.3334 41.3334 50.3486 41.3334 46.6667C41.3334 42.9848 44.3181 40.0001 48 40.0001C51.6819 40.0001 54.6667 42.9848 54.6667 46.6667Z'
										stroke='white'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Give us your design</h6>
							<p>
								The company was established in 2000 as Sri Lanka’s
								pioneer manufacturer of steel wardrobes and tables.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className='customisedProducts-section-form'>
				<CustomisedForm />
			</div>

			<div className='customisedProducts-section-partners'>
				<Partners />
			</div>
			<Footer></Footer>
		</div>
	);
}
