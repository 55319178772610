import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

// Home styles
import './Home.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import Header from '../../components/header/Header';
import Hero from '../../components/hero/Hero';
import Partners from '../../components/partners/Partners';
import ProductCart from '../../components/product-cart/ProductCart';
import CustomProduct from '../../components/custom-product/CustomProduct';

import NavBar from './../../components/navbar/NavBar';
import Footer from './../../components/footer/Footer';

// Assets
import IMAGE_HEADER from './../../assets/images/home/img-header.png';
import IMAGE_SECTION_ONE from './../../assets/images/home/img-home-section-1.png';
import IMAGE_SECTION_TWO from './../../assets/images/home/img-home-section-1-2.png';
import IMAGE_PRODUCT_ONE from './../../assets/images/home/img-home-product-1.png';
import IMAGE_PRODUCT_TWO from './../../assets/images/home/img-home-product-2.png';
import IMAGE_PRODUCT_THREE from './../../assets/images/home/img-home-product-3.png';

import IMAGE_DRESSING_TABLE_1 from './../../assets/images/products/melamine/dressing-tables/MWDT001.jpg';
import IMAGE_DRESSING_TABLE_2 from './../../assets/images/products/melamine/dressing-tables/MWDT002.jpg';
import IMAGE_DRESSING_TABLE_3 from './../../assets/images/products/melamine/dressing-tables/MWDT003.jpg';
import IMAGE_WRITING_TABLE_1 from './../../assets/images/products/melamine/writing-tables/MWWT001.jpg';
import IMAGE_WRITING_TABLE_2 from './../../assets/images/products/melamine/writing-tables/MWWT002.jpg';
import IMAGE_WRITING_TABLE_3 from './../../assets/images/products/melamine/writing-tables/MWWT003.jpg';
import IMAGE_STUDY_TABLE_1 from './../../assets/images/products/melamine/study-tables/MWST001.jpg';
import IMAGE_STUDY_TABLE_2 from './../../assets/images/products/melamine/study-tables/MWST002.jpg';
import IMAGE_STUDY_TABLE_3 from './../../assets/images/products/melamine/study-tables/MWST003.jpg';

import IMAGE_FILING_CABINET_1 from './../../assets/images/products/steel/filing-cabinet/2-DRAWER-FILING-CABINET.jpg';
import IMAGE_FILING_CABINET_2 from './../../assets/images/products/steel/filing-cabinet/3-DRAWER-FILING-CABINET.jpg';
import IMAGE_FILING_CABINET_3 from './../../assets/images/products/steel/filing-cabinet/4-DRAWER-FILING-CABINET.jpg';
import IMAGE_DELUXE_WARDROBE_1 from './../../assets/images/products/steel/deluxe-wardrobe/GOLD-DELUXE-STD-WARDROBE-1.jpg';
import IMAGE_DELUXE_WARDROBE_2 from './../../assets/images/products/steel/deluxe-wardrobe/GOLD-DELUXE-STD-WARDROBE-1.jpg';
import IMAGE_DELUXE_WARDROBE_3 from './../../assets/images/products/steel/deluxe-wardrobe/GOLD-DELUXE-STD-WARDROBE-1.jpg';
import IMAGE_CASH_BOX_1 from './../../assets/images/products/steel/cash-box/CASH-BOX.jpg';
import IMAGE_CASH_BOX_2 from './../../assets/images/products/steel/cash-box/CASH-BOX.jpg';
import IMAGE_CASH_BOX_3 from './../../assets/images/products/steel/cash-box/CASH-BOX-2.jpg';
import LOGO_WHITE from './../../assets/logos/Milro-Industries-(PVT)-Ltd-WHITE.png';

// import IMAGE_COLLECTION_ONE from './../../assets/images/home/img-home-collection-1.png';
// import IMAGE_COLLECTION_TWO from './../../assets/images/home/img-home-collection-2.png';
// import IMAGE_COLLECTION_THREE from './../../assets/images/home/img-home-collection-3.png';
// import IMAGE_COLLECTION_FOUR from './../../assets/images/home/img-home-collection-4.png';
// import IMAGE_COLLECTION_FIVE from './../../assets/images/home/img-home-collection-5.png';
// import IMAGE_COLLECTION_SIX from './../../assets/images/home/img-home-collection-6.png';

export default function Home() {
	useEffect(() => {
		AOS.init();
	}, [])
	return (
		<div className='home'>
			<NavBar></NavBar>

			<Header height={'80vh'} bgImage={IMAGE_HEADER}>
				<Hero marginT={'400px'}>
					<div className='row'>
						<img className='logo-mobile' src={LOGO_WHITE} alt='' />
						<div className='col-md-4'>
							<p data-aos="fade-up">Est. 2000</p>
							<h1 data-aos="fade-up">
								MILRO <br /> INDUSTRIES
							</h1>
							<p data-aos="fade-up">
								We are the pioneering leader in the manufacturing of
								steel furniture
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4 first-col order-3 order-md-1'>
							<button className='btn-secondary' data-aos="fade-up">
								<Link to='/products'>View our products</Link>
							</button>
						</div>
						<div className='col-md-4 second-col order-1 '>
							<p data-aos="fade-up">
								At Milro, we’re here to help you create a space that
								reflects you and what you love.
							</p>
						</div>
						<div className='col-md-4 third-col order-2 '>
							<p data-aos="fade-up">
								We’ll put it all together for you. Free one-on-one
								design help, in our store or in your home!
							</p>
						</div>
					</div>
				</Hero>
			</Header>

			<div className='home-section-one'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6 order-2 order-md-1'>
							<img
								className='first-img'
								src={IMAGE_SECTION_ONE}
								alt=''
							/>
							<h3 className='call-us'>
								Let’s start the design, Call us <br />{' '}
								<a href='tel:+94113024001'>011 30 24 001</a>
							</h3>
						</div>
						<div className='col-md-6 second-col order-1'>
							<h2>Premium quality and designs created for you</h2>
							<p>
								Milro Industries (PVT) Ltd: A Legacy of Excellence in Steel and Melamine Furniture <br />
								<br /> Established in 2000, Milro Industries (PVT) Ltd has emerged as a pioneering leader in the manufacturing of steel furniture. Over the past two decades, we have continuously innovated and refined our approach to steel furniture manufacturing, solidifying our position as a market leader.
							</p>
							<div className='row sub-row'>
								<div className='col-md-6 order-2 order-md-1'>
									<button className='btn-primary btn-sml'>
										<Link to='/about-us' style={{ textDecoration: 'none', color: '#fff' }}>About us</Link>
									</button>
								</div>
								<div className='col-md-6 order-1'>
									{/* <img src={IMAGE_SECTION_TWO} alt='' /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='home-section-two'>
				<div className='container'>
					<div className='crl-wrapper'>
						<span className='crl crl-1'></span>
						<span className='crl'></span>
						<span className='crl crl-2'></span>
					</div>

					<h2 className='heading'>Our product range</h2>
					<div className='row'>
						<div className='col-md-6 img-col'>
							<img data-aos="fade-right" src={IMAGE_PRODUCT_ONE} alt='' />
						</div>
						<div className='col-md-6 text-col'>
							<h2 data-aos="fade-left">Office Furniture</h2>
							<p data-aos="fade-left">
								Craft your ideal workspace with versatile office furniture.
							</p>
							<button className='btn-secondary' data-aos="fade-left">
								<Link to={'/products'}>View products</Link>
							</button>
						</div>
					</div>

					<div className='row middle-row'>
						<div className='col-md-6 text-col order-2 order-md-1'>
							<h2 data-aos="fade-right">Home Furniture</h2>
							<p data-aos="fade-right">
								Where comfort meets style.
							</p>
							<button className='btn-secondary' data-aos="fade-right">
								<Link to={'/products'}>View products</Link>
							</button>
						</div>
						<div className='col-md-6 img-col order-1 order-md-2'>
							<img data-aos="fade-left" src={IMAGE_PRODUCT_THREE} alt='' />
						</div>
					</div>

					<div className='row'>
						<div className='col-md-6 img-col'>
							<img data-aos="fade-right" src={IMAGE_PRODUCT_TWO} alt='' />
						</div>
						<div className='col-md-6 text-col'>
							<h2 data-aos="fade-left">Workmen Lockers</h2>
							<p data-aos="fade-left">
								Protect your tools and belongings with rugged lockers built to withstand tough environments
							</p>
							<button className='btn-secondary' data-aos="fade-left">
								<Link to={'/products'}>View products</Link>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className='home-section-partners'>
				<Partners />
			</div>

			<div className='home-section-latest-collection'>
				<div className='container'>
					<h2>Latest Collection</h2>
					<p>
						Shop the latest trends in furniture and design with <br /> our
						newest collection
					</p>
					<div className='row'>
						<ProductCart
							name='Dressing Table'
							prodCode={'MWDT 001'}
							productId={IMAGE_DRESSING_TABLE_1}
							productThreeD={'workstation_table.glb'}
							dimentions={'H 69" x W 19" x D 16"'}
						>
							<img src={IMAGE_DRESSING_TABLE_1} alt='' />
						</ProductCart>

						<ProductCart
							name='Writing Table'
							prodCode={'MWWT 001'}
							productId={IMAGE_WRITING_TABLE_1}
							dimentions={'H 36" x W 18" x D 30"'}
						>
							<img src={IMAGE_WRITING_TABLE_1} alt='' />
						</ProductCart>

						<ProductCart
							name='Study Table'
							prodCode={'MWST 001'}
							productId={IMAGE_STUDY_TABLE_1}
							dimentions={'H 36" x W 18" x D 48"'}
						>
							<img src={IMAGE_STUDY_TABLE_1} alt='' />
						</ProductCart>

						<ProductCart
							name='Steel Filing Cabinet'
							prodCode={'MSOF 009'}
							productId={IMAGE_FILING_CABINET_1}
							productId2={IMAGE_FILING_CABINET_2}
							productId3={IMAGE_FILING_CABINET_3}
							dimentions={'H 28" x W 18.5" x D 24"'}
						>
							<img src={IMAGE_FILING_CABINET_1} alt='' />
						</ProductCart>

						<ProductCart
							name='Steel Cash Box'
							prodCode={'MPCOB 026'}
							productId={IMAGE_CASH_BOX_1}
							productId2={IMAGE_CASH_BOX_2}
							productId3={IMAGE_CASH_BOX_3}
							dimentions={'L 12” W 07” H 05”'}
						>
							<img src={IMAGE_CASH_BOX_1} alt='' />
						</ProductCart>

						<ProductCart
							name='Gold Deluxe STD Wardrobe'
							prodCode='MPCWR 017'
							productId={IMAGE_DELUXE_WARDROBE_1}
							productId2={IMAGE_DELUXE_WARDROBE_2}
							productId3={IMAGE_DELUXE_WARDROBE_3}
							desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
							dimentions={'H 75" x W 47" x D 20"'}
						>
							<img src={IMAGE_DELUXE_WARDROBE_1} alt='' />
						</ProductCart>
					</div>
					<button className='btn-secondary-two'>
						<Link to={'/products'}>View full collection</Link>
					</button>
				</div>
			</div>

			<div className='home-section-custom-product'>
				<CustomProduct marginT={'80px'} />
			</div>
			<Footer></Footer>
		</div>
	);
}
