import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Home from './pages/home/Home';
import Products from './pages/products/Products';
import CustomisedProducts from './pages/customised-products/CustomisedProducts';
import Dealers from './pages/dealers/Dealers';
import AboutUs from './pages/about-us/AboutUs';
import ContactUs from './pages/contact-us/ContactUs';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Home />} />
				<Route path='products' element={<Products />} />
				<Route path='custom-product' element={<CustomisedProducts />} />
				<Route path='dealers' element={<Dealers />} />
				<Route path='about-us' element={<AboutUs />} />
				<Route path='contact-us' element={<ContactUs />} />
			</Routes>
		</BrowserRouter>
	);
}
