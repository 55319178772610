// Img view styles
import './ImgView.scss';

export default function ImgView({ name, desc, prodCode, productId, productId2, productId3, setBtnImgViewActive, dimentions }) {
	console.log(productId);
	return (
		<div className='img-view'>
			<div class='overlay'></div>
			<div className='container '>
				<div className='wrapper'>
					<h3>{name}</h3>
					<div className='row'>
						<div className='col-md-8'>
							<p>{desc ? desc : ''}</p>
						</div>
						<div className='col-md-4'>
							<span className="dimentions">{dimentions}</span>
						</div>
					</div>
					<span
						class='btn-close'
						onClick={() => setBtnImgViewActive(false)}
					>
						{/* <svg
							xmlns='http://www.w3.org/2000/svg'
							width='17'
							height='17'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								d='M15 5L5 15M5 5L15 15'
								stroke='black'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg> */}
					</span>
					{productId2 != null &&
						<div className='img-container'>
							<div className='img-wrapper img-1'>
								<img src={productId} alt='' />
							</div>
							<div className='img-wrapper img-2'>
								<img src={productId2} alt='' />
							</div>
							<div className='img-wrapper img-3'>
								<img src={productId3} alt='' />
							</div>
							{prodCode &&
								<div className=''>
									<span className='prod-code'>Product Code: {prodCode}</span>
								</div>
							}
						</div>
					}
					{productId2 == null &&
						<div className='img-container-full'>
							<div className='img-wrapper img-1-full'>
								<img className='single-img' src={productId} alt='' />
								{prodCode &&
									<span className='prod-code'>Product Code: {prodCode}</span>
								}
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
