// Styles
import { useState } from 'react';
import './ContactUsForm.scss';

export default function ContactUsForm() {
	// useState hook
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [company, setCompany] = useState('');
	const [message, setMessage] = useState('');

	return (
		<div className='container'>
			<div className='contact-us-form-container'>
				<div className='text-wrapper'>
					<h2>Fill this form</h2>
					<p>
						The company was established in 2000 as Sri Lanka’s pioneer
						manufacturer of steel wardrobes and tables.
					</p>
				</div>

				<form action=''>
					<div className='form-wrapper'>
						<div className='input-wrapper'>
							<input
								type='text'
								id='fname'
								name='fname'
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<label htmlFor='fname'>First name</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='email'
								name='email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<label htmlFor='email'>Email Address</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='contact'
								name='contact'
								required
								value={contact}
								onChange={(e) => setContact(e.target.value)}
							/>
							<label htmlFor='contact'>Contact Number</label>
						</div>

						<div className='input-wrapper'>
							<input
								type='text'
								id='cname'
								name='cname'
								required
								value={company}
								onChange={(e) => setCompany(e.target.value)}
							/>
							<label htmlFor='cname'>Company Name</label>
						</div>

						{/* Text area */}
						<div className='input-wrapper msg-wrapper'>
							<textarea
								id='edetails'
								name='edetails'
								required
								rows='4'
								cols='50'
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							></textarea>
							<label htmlFor='edetails'>Your Message</label>
						</div>
					</div>

					<div className='input-wrapper'>
						<input
							className='btn-primary submit-btn'
							type='submit'
							value='Submit'
						/>
					</div>
				</form>
			</div>
		</div>
	);
}
