// Components
import ThreeDModel from '../three-d-model/ThreeDModel';

// 3D view styles
import './ThreeDView.scss';

export default function ThreeDView({ name, productThreeD, setBtn3dActive }) {
	return (
		<div className='three-d-view'>
			<div class='overlay'></div>
			<div className='container '>
				<div className='wrapper'>
					<h3>{name}</h3>
					<span class='btn-close' onClick={() => setBtn3dActive(false)}>
						{/* <svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								d='M15 5L5 15M5 5L15 15'
								stroke='black'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg> */}
					</span>
					<div className='three-d-container'>
						<ThreeDModel productThreeD={productThreeD} />
					</div>
				</div>
			</div>
		</div>
	);
}
