import { Link } from 'react-router-dom';

// Products styles
import './Products.scss';

// Components
import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/Hero';
import ProductCart from '../../components/product-cart/ProductCart';
import CustomProduct from '../../components/custom-product/CustomProduct';

// Assert
import IMAGE_HEADER from './../../assets/images/home/img-header.png';
import IMAGE_PRODUCT_ONE from './../../assets/images/products/img-product-1.png';
import IMAGE_PRODUCT_TWO from './../../assets/images/products/img-product-2.png';
import IMAGE_PRODUCT_THREE from './../../assets/images/products/img-product-3.png';
import IMAGE_PRODUCT_FOUR from './../../assets/images/products/img-product-4.png';
import IMAGE_PRODUCT_FIVE from './../../assets/images/products/img-product-5.png';
import IMAGE_PRODUCT_SIX from './../../assets/images/products/img-product-6.png';

import LOGO_ELEGANT from './../../assets/images/partners-logo/logo-elegent.png';
import LOGO_SINGER from './../../assets/images/partners-logo/logo-singer.png';
import LOGO_FURNITURE from './../../assets/images/partners-logo/logo-furniture.png';

// Products
import MM_IMAGE_OFF_WRT_TBL_1 from './../../assets/images/products/melamine/writing-tables/MWWT001.jpg';
import MM_IMAGE_OFF_WRT_TBL_2 from './../../assets/images/products/melamine/writing-tables/MWWT002.jpg';
import MM_IMAGE_OFF_WRT_TBL_3 from './../../assets/images/products/melamine/writing-tables/MWWT003.jpg';

import MM_IMAGE_OFF_STDY_TBL_1 from './../../assets/images/products/melamine/study-tables/MWST001.jpg';
import MM_IMAGE_OFF_STDY_TBL_2 from './../../assets/images/products/melamine/study-tables/MWST002.jpg';
import MM_IMAGE_OFF_STDY_TBL_3 from './../../assets/images/products/melamine/study-tables/MWST003.jpg';
import MM_IMAGE_OFF_STDY_TBL_4 from './../../assets/images/products/melamine/study-tables/MWST004.jpg';

import MM_IMAGE_OFF_CMPTR_TBL_1 from './../../assets/images/products/melamine/computer-tables/MWCT001.jpg';
import MM_IMAGE_OFF_CMPTR_TBL_2 from './../../assets/images/products/melamine/computer-tables/MWCT002.jpg';

import MM_IMAGE_OFF_BK_RKS_1 from './../../assets/images/products/melamine/book-racks/MWBR001.jpg';
import MM_IMAGE_OFF_BK_RKS_2 from './../../assets/images/products/melamine/book-racks/MWBR002.jpg';
import MM_IMAGE_OFF_BK_RKS_3 from './../../assets/images/products/melamine/book-racks/MWBR003.jpg';
import MM_IMAGE_OFF_BK_RKS_4 from './../../assets/images/products/melamine/book-racks/MWBR004.jpg';
import MM_IMAGE_OFF_BK_RKS_5 from './../../assets/images/products/melamine/book-racks/MWBR005.jpg';

// import MM_IMAGE_OFF_CPBRD_5 from './../../assets/images/products/melamine/cupboards/';

import MM_IMAGE_HM_WLL_1 from './../../assets/images/products/melamine/wall-units/MWWU001.jpg';
import MM_IMAGE_HM_WLL_2 from './../../assets/images/products/melamine/wall-units/MWWU002.jpg';

import MM_IMAGE_HM_DRSNG_TBL_1 from './../../assets/images/products/melamine/dressing-tables/MWDT001.jpg';
import MM_IMAGE_HM_DRSNG_TBL_2 from './../../assets/images/products/melamine/dressing-tables/MWDT002.jpg';
import MM_IMAGE_HM_DRSNG_TBL_3 from './../../assets/images/products/melamine/dressing-tables/MWDT003.jpg';
import MM_IMAGE_HM_DRSNG_TBL_4 from './../../assets/images/products/melamine/dressing-tables/MWDT004.jpg';
import MM_IMAGE_HM_DRSNG_TBL_5 from './../../assets/images/products/melamine/dressing-tables/MWDT005.jpg';

import MM_IMAGE_HM_WRDRBS_1 from './../../assets/images/products/melamine/wardrobes/MWW001.jpg';
import MM_IMAGE_HM_WRDRBS_2 from './../../assets/images/products/melamine/wardrobes/MWW002.jpg';
import MM_IMAGE_HM_WRDRBS_3 from './../../assets/images/products/melamine/wardrobes/MWW003.jpg';
import MM_IMAGE_HM_WRDRBS_4 from './../../assets/images/products/melamine/wardrobes/MWW004.jpg';

import MM_IMAGE_HM_IRN_CPBD_1 from './../../assets/images/products/melamine/iron-cupboards/MWIC001.jpg';
import MM_IMAGE_HM_IRN_CPBD_2 from './../../assets/images/products/melamine/iron-cupboards/MWIC002.jpg';

// Steel
import MS_IMAGE_OF_2DRWR_FLNG_CBNT_1 from './../../assets/images/products/steel/2-drawer-filing-cabinet/MSOF-009-01.jpg';

// import MS_IMAGE_OF_3DRWR_FLNG_CBNT_1 from './../../assets/images/products/steel/3-drawer-filing-cabinet/MSOF-010-01.jpg';

import MS_IMAGE_OF_4DRWR_FLNG_CBNT_1 from './../../assets/images/products/steel/4-drawer-filing-cabinet/MSOF-011-01.jpg';

import MS_IMAGE_OF_3_DRS_WRDB_1 from './../../assets/images/products/steel/three-doors-wardrobe/MSWR-001-01.jpg';
import MS_IMAGE_OF_3_DRS_WRDB_2 from './../../assets/images/products/steel/three-doors-wardrobe/MSWR-001-02.jpg';

import MS_IMAGE_OF_DRSNG_TBL_WRDB_1 from './../../assets/images/products/steel/dressing-table-wardrobe/MSWR-002-01.jpg';
import MS_IMAGE_OF_DRSNG_TBL_WRDB_2 from './../../assets/images/products/steel/dressing-table-wardrobe/MSWR-002-02.jpg';

import MS_IMAGE_OF_DBL_LCK_STD_WRDB_1 from './../../assets/images/products/steel/double-lock-std-wardrobe/MSWR-003-01.jpg';
import MS_IMAGE_OF_DBL_LCK_STD_WRDB_2 from './../../assets/images/products/steel/double-lock-std-wardrobe/MSWR-003-02.jpg';
import MS_IMAGE_OF_DBL_LCK_STD_WRDB_3 from './../../assets/images/products/steel/double-lock-std-wardrobe/MSWR-003-03.jpg';

import MS_IMAGE_OF_STD_MINI_WRDB_1 from './../../assets/images/products/steel/std-mini-wardrobe/MSWR-004-01.jpg';
import MS_IMAGE_OF_STD_MINI_WRDB_2 from './../../assets/images/products/steel/std-mini-wardrobe/MSWR-004-02.jpg';
import MS_IMAGE_OF_STD_MINI_WRDB_3 from './../../assets/images/products/steel/std-mini-wardrobe/MSWR-004-03.jpg';


import MS_IMAGE_OF_DLX_STD_WRDB_1 from './../../assets/images/products/steel/delux-std-wardrobe/MSWR-005-01.jpg';
import MS_IMAGE_OF_DLX_STD_WRDB_2 from './../../assets/images/products/steel/delux-std-wardrobe/MSWR-005-02.jpg';

import MS_IMAGE_OF_JNR_STD_WRDB_1 from './../../assets/images/products/steel/junior-std-wardrobe/MSWR-006-01.jpg';
import MS_IMAGE_OF_JNR_STD_WRDB_2 from './../../assets/images/products/steel/junior-std-wardrobe/MSWR-006-02.jpg';

import MS_IMAGE_OF_ECNMY_WRDB_1 from './../../assets/images/products/steel/economy-wardrobe/MSWR-007-01.jpg';
import MS_IMAGE_OF_ECNMY_WRDB_2 from './../../assets/images/products/steel/economy-wardrobe/MSWR-007-02.jpg';
import MS_IMAGE_OF_ECNMY_WRDB_3 from './../../assets/images/products/steel/economy-wardrobe/MSWR-007-03.jpg';
import MS_IMAGE_OF_ECNMY_WRDB_4 from './../../assets/images/products/steel/economy-wardrobe/MSWR-007-04.jpg';

import MS_IMAGE_OF_STD_SLVR_WRDB_1 from './../../assets/images/products/steel/std-silver-wardrobe/MSWR-011-01.jpg';
import MS_IMAGE_OF_STD_SLVR_WRDB_2 from './../../assets/images/products/steel/std-silver-wardrobe/MSWR-011-02.jpg';

import MS_IMAGE_OF_OFIC_CPBD_1 from './../../assets/images/products/steel/office-cupboard/MSOC-001-01.jpg';
import MS_IMAGE_OF_OFIC_CPBD_2 from './../../assets/images/products/steel/office-cupboard/MSOC-001-02.jpg';

import MS_IMAGE_OF_LBRY_CPBD_1 from './../../assets/images/products/steel/library-cupboard/MSOC-002-01.jpg';
import MS_IMAGE_OF_LBRY_CPBD_2 from './../../assets/images/products/steel/library-cupboard/MSOC-002-02.jpg';

import MS_IMAGE_OF_JNR_LBRY_CPBD_1 from './../../assets/images/products/steel/junior-library-cupboard/MSOC-003.jpg';


import MS_IMAGE_OF_MDM_CPBD_WOD_1 from './../../assets/images/products/steel/medium-cupboard-without-drawer/MSOC-004-01.jpg';
import MS_IMAGE_OF_MDM_CPBD_WOD_2 from './../../assets/images/products/steel/medium-cupboard-without-drawer/MSOC-004-02.jpg';
import MS_IMAGE_OF_MDM_CPBD_WOD_3 from './../../assets/images/products/steel/medium-cupboard-without-drawer/MSOC-004-03.jpg';

import MS_IMAGE_OF_MDM_CPBD_1D_1 from './../../assets/images/products/steel/medium-cupboard-1-drawer/MSOC-005-01.jpg';
import MS_IMAGE_OF_MDM_CPBD_1D_2 from './../../assets/images/products/steel/medium-cupboard-1-drawer/MSOC-005-02.jpg';

import MS_IMAGE_OF_MDM_CPBD_2D_1 from './../../assets/images/products/steel/medium-cupboard-2-drawer/MSOC-006-01.jpg';
import MS_IMAGE_OF_MDM_CPBD_2D_2 from './../../assets/images/products/steel/medium-cupboard-2-drawer/MSOC-006-02.jpg';

import MS_IMAGE_OF_SML_BOOK_RCK_1 from './../../assets/images/products/steel/small-book-rack/MSOR-007-01.jpg';
import MS_IMAGE_OF_SML_BOOK_RCK_2 from './../../assets/images/products/steel/small-book-rack/MSOR-007-02.jpg';

import MS_IMAGE_OF_LRG_BOOK_RCK_1 from './../../assets/images/products/steel/large-book-rack/MSOR-008-01.jpg';
import MS_IMAGE_OF_LRG_BOOK_RCK_2 from './../../assets/images/products/steel/large-book-rack/MSOR-008-02.jpg';

import MS_IMAGE_OF_CSHR_TBL from './../../assets/images/products/steel/cashier-table/MSTB-002-01.jpg';

import MS_IMAGE_OF_TYPST_TBL from './../../assets/images/products/steel/typist-table/MSTB-003-01.jpg';

import MS_IMAGE_OF_CLRCL_TBL from './../../assets/images/products/steel/clerical -table/MSTB-004-01.jpg';

import MS_IMAGE_OF_JNR_EXCTV_TBL from './../../assets/images/products/steel/junior-executive-table/MSTB-005-01.jpg';

import MS_IMAGE_OF_WRKMN_LCKR_1 from './../../assets/images/products/steel/workmen-locker-15-units/MSOW-012-01.jpg';
import MS_IMAGE_OF_WRKMN_LCKR_2 from './../../assets/images/products/steel/workmen-locker-15-units/MSOW-012-02.jpg';

import MS_IMAGE_OF_GOLD_3DRS_WRDB from './../../assets/images/products/steel/gold-three-doors-wardrobe/MPCWR-013.jpg';

import MS_IMAGE_OF_GOLD_DRSN_TBL_WRDB from './../../assets/images/products/steel/gold-dressing-table-wardrobe/MPCWR-014.jpg';

import MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_1 from './../../assets/images/products/steel/gold-double-lock-std-wardrobe/MPCWR-015-01.jpg';
import MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_2 from './../../assets/images/products/steel/gold-double-lock-std-wardrobe/MPCWR-015-02.jpg';

import MS_IMAGE_OF_GOLD_STD_MINI_WRDB_1 from './../../assets/images/products/steel/gold-std-mini-wardrobe/MPCWR-016-01.jpg';
import MS_IMAGE_OF_GOLD_STD_MINI_WRDB_2 from './../../assets/images/products/steel/gold-std-mini-wardrobe/MPCWR-016-02.jpg';
import MS_IMAGE_OF_GOLD_STD_MINI_WRDB_3 from './../../assets/images/products/steel/gold-std-mini-wardrobe/MPCWR-016-03.jpg';

import MS_IMAGE_OF_GOLD_DLX_STD_WRDB_1 from './../../assets/images/products/steel/gold-delux-std-wardrobe/MPCWR-017-01.jpg';
import MS_IMAGE_OF_GOLD_DLX_STD_WRDB_2 from './../../assets/images/products/steel/gold-delux-std-wardrobe/MPCWR-017-02.jpg';
import MS_IMAGE_OF_GOLD_DLX_STD_WRDB_3 from './../../assets/images/products/steel/gold-delux-std-wardrobe/MPCWR-017-03.jpg';

import MS_IMAGE_OF_GOLD_JNR_STD_WRDB_1 from './../../assets/images/products/steel/gold-junior-std-wardrobe/MPCWR-018-01.jpg';
import MS_IMAGE_OF_GOLD_JNR_STD_WRDB_2 from './../../assets/images/products/steel/gold-junior-std-wardrobe/MPCWR-018-02.jpg';
import MS_IMAGE_OF_GOLD_JNR_STD_WRDB_3 from './../../assets/images/products/steel/gold-junior-std-wardrobe/MPCWR-018-03.jpg';

import MS_IMAGE_OF_GOLD_ECNMY_WRDB_1 from './../../assets/images/products/steel/gold-economy-wardrobe/MPCWR-019-01.jpg';
import MS_IMAGE_OF_GOLD_ECNMY_WRDB_2 from './../../assets/images/products/steel/gold-economy-wardrobe/MPCWR-019-02.jpg';
import MS_IMAGE_OF_GOLD_ECNMY_WRDB_3 from './../../assets/images/products/steel/gold-economy-wardrobe/MPCWR-019-03.jpg';

import MS_IMAGE_OF_GOLD_PPLR_WRDB_1 from './../../assets/images/products/steel/gold-popular-wardrobe/MPCWR-020-01.jpg';
import MS_IMAGE_OF_GOLD_PPLR_WRDB_2 from './../../assets/images/products/steel/gold-popular-wardrobe/MPCWR-020-02.jpg';

import IMAGE_CASH_BOX_1 from './../../assets/images/products/steel/cash-box/CASH-BOX.jpg';
import IMAGE_CASH_BOX_2 from './../../assets/images/products/steel/cash-box/CASH-BOX.jpg';
import IMAGE_CASH_BOX_3 from './../../assets/images/products/steel/cash-box/CASH-BOX-2.jpg';

import { useState } from 'react';



export default function Products() {
	const [mainCatClicked, setMainCatClicked] = useState(false);
	const [category, setCategory] = useState('all');

	function handleCategoryClick() {
		setMainCatClicked(!mainCatClicked)
	}

	function handleCatChange(category) {
		setCategory(category);
	}

	return (
		<div className='products'>
			<NavBar></NavBar>
			<Header bgImage={IMAGE_HEADER}>
				<Hero>
					<h1>
						our <br /> products
					</h1>
				</Hero>
			</Header>

			<div className='products-section-one'>
				<div className='container'>
					<button className='btn-secondary-two' onClick={handleCategoryClick}>
						Filter by
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 32 32'
							fill='none'
						>
							<path
								d='M8 12L16 20L24 12'
								stroke='#1D2670'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
					</button>
					{mainCatClicked === true &&
						<span className='dropdown-content'>
							<ul>
								<li onClick={() => {
									handleCatChange("all");
								}}>All</li>
								<li onClick={() => {
									handleCatChange("melamine home");
								}}>Melamine Home furniture</li>
								<li onClick={() => {
									handleCatChange("melamine office");
								}}>Melamine Office furniture</li>
								<li onClick={() => {
									handleCatChange("steel home");
								}}>Steel Home furniture</li>
								<li onClick={() => {
									handleCatChange("steel office");
								}}>Steel Office furniture</li>
							</ul>
						</span>
					}
					<div className='row'>
						{(category === 'melamine office' || category === 'office' || category === 'all') &&
							<>
								<ProductCart
									name='Writing Table'
									prodCode='MWWT001'
									productId={MM_IMAGE_OFF_WRT_TBL_1}
									dimentions={'H 36" x W 18" x D 30"'}
								>
									<img src={MM_IMAGE_OFF_WRT_TBL_1} alt='Writing Table' />
								</ProductCart>
								<ProductCart
									name='Writing Table'
									prodCode='MWWT002'
									productId={MM_IMAGE_OFF_WRT_TBL_2}
									dimentions={'H 48" x W 24" x D 30"'}
								>
									<img src={MM_IMAGE_OFF_WRT_TBL_2} alt='Writing Table' />
								</ProductCart>
								<ProductCart
									name='Writing Table'
									prodCode='MWWT003'
									productId={MM_IMAGE_OFF_WRT_TBL_3}
									dimentions={'H 48" x W 30" x D 30"'}
								>
									<img src={MM_IMAGE_OFF_WRT_TBL_3} alt='Writing Table' />
								</ProductCart>
								<ProductCart
									name='Study Table'
									prodCode='MWST001'
									productId={MM_IMAGE_OFF_STDY_TBL_1}
									dimentions={'H 36" x W 18" x D 48"'}
								>
									<img src={MM_IMAGE_OFF_STDY_TBL_1} alt='Study Table' />
								</ProductCart>
								<ProductCart
									name='Study Table'
									prodCode='MWST002'
									productId={MM_IMAGE_OFF_STDY_TBL_2}
									dimentions={'H 48" x W 24" x D 48"'}
								>
									<img src={MM_IMAGE_OFF_STDY_TBL_2} alt='Study Table' />
								</ProductCart>
								<ProductCart
									name='Study Table'
									prodCode='MWST003'
									productId={MM_IMAGE_OFF_STDY_TBL_3}
									dimentions={'H 48" x W 24" x D 48"'}
								>
									<img src={MM_IMAGE_OFF_STDY_TBL_3} alt='Study Table' />
								</ProductCart>
								<ProductCart
									name='Study Table'
									prodCode='MWST004'
									productId={MM_IMAGE_OFF_STDY_TBL_4}
									dimentions={'H 48" x W 24" x D 54"'}
								>
									<img src={MM_IMAGE_OFF_STDY_TBL_4} alt='Study Table' />
								</ProductCart>
								<ProductCart
									name='Computer Table'
									prodCode='MWCT001'
									productId={MM_IMAGE_OFF_CMPTR_TBL_1}
									dimentions={'H 24" x W 16" x D 30"'}
								>
									<img src={MM_IMAGE_OFF_CMPTR_TBL_1} alt='Computer Table' />
								</ProductCart>
								<ProductCart
									name='Computer Table'
									prodCode='MWCT002'
									productId={MM_IMAGE_OFF_CMPTR_TBL_2}
									dimentions={'H 36" x W 19" x D 30"'}
								>
									<img src={MM_IMAGE_OFF_CMPTR_TBL_2} alt='Computer Table' />
								</ProductCart>
								<ProductCart
									name='Book Rack'
									prodCode='MWBR001'
									productId={MM_IMAGE_OFF_BK_RKS_1}
									dimentions={'H 48" x W 24" x D 12"'}
								>
									<img src={MM_IMAGE_OFF_BK_RKS_1} alt='Book Rack' />
								</ProductCart>
								<ProductCart
									name='Book Rack'
									prodCode='MWBR002'
									productId={MM_IMAGE_OFF_BK_RKS_2}
									dimentions={'H 60" x W 24" x D 12"'}
								>
									<img src={MM_IMAGE_OFF_BK_RKS_2} alt='Book Rack' />
								</ProductCart>
								<ProductCart
									name='Book Rack'
									prodCode='MWBR003'
									productId={MM_IMAGE_OFF_BK_RKS_3}
									dimentions={'H 48" x W 32" x D 16"'}
								>
									<img src={MM_IMAGE_OFF_BK_RKS_3} alt='Book Rack' />
								</ProductCart>
							</>
						}
						{(category === 'melamine home' || category === 'home' || category === 'all') &&
							<>
								<ProductCart
									name='Wall Unit'
									prodCode='MWWU001'
									productId={MM_IMAGE_HM_WLL_1}
									dimentions={'H 72" x W 32" x D 16"'}
								>
									<img src={MM_IMAGE_HM_WLL_1} alt='Wall Unit' />
								</ProductCart>
								<ProductCart
									name='Wall Unit'
									prodCode='MWWU 002'
									productId={MM_IMAGE_HM_WLL_2}
									dimentions={'H 72" x W 48" x D 16"'}
								>
									<img src={MM_IMAGE_HM_WLL_2} alt='Wall Unit' />
								</ProductCart>
								<ProductCart
									name='Dressing Table'
									prodCode='MWDT 001'
									productId={MM_IMAGE_HM_DRSNG_TBL_1}
									dimentions={'H 69" x W 19" x D 16"'}
								>
									<img src={MM_IMAGE_HM_DRSNG_TBL_1} alt='Dressing Table' />
								</ProductCart>
								<ProductCart
									name='Dressing Table'
									prodCode='MWDT 002'
									productId={MM_IMAGE_HM_DRSNG_TBL_2}
									dimentions={'H 72" x W 24" x D 16"'}
								>
									<img src={MM_IMAGE_HM_DRSNG_TBL_2} alt='Dressing Table' />
								</ProductCart>
								<ProductCart
									name='Dressing Table With Stool'
									prodCode='MWDT 003'
									productId={MM_IMAGE_HM_DRSNG_TBL_3}
									dimentions={'H 72" x W 24" x D 19"'}
								>
									<img src={MM_IMAGE_HM_DRSNG_TBL_3} alt='Dressing Table With Stool' />
								</ProductCart>
								<ProductCart
									name='Dressing Table With Stool'
									prodCode='MWDT 004'
									productId={MM_IMAGE_HM_DRSNG_TBL_4}
									dimentions={'H 74" x W 36" x D 16"'}
								>
									<img src={MM_IMAGE_HM_DRSNG_TBL_4} alt='Dressing Table With Stool' />
								</ProductCart>
								<ProductCart
									name='Dressing Table With Stool'
									prodCode='MWDT 005'
									productId={MM_IMAGE_HM_DRSNG_TBL_5}
									dimentions={'H 72" x W 32" x D 16"'}
								>
									<img src={MM_IMAGE_HM_DRSNG_TBL_5} alt='Dressing Table With Stool' />
								</ProductCart>
								<ProductCart
									name='Wardrobes'
									prodCode='MWW (6x3) 001'
									productId={MM_IMAGE_HM_WRDRBS_1}
									dimentions={'H 72" x W 32" x D 16"'}
								>
									<img src={MM_IMAGE_HM_WRDRBS_1} alt='Wardrobes' />
								</ProductCart>
								<ProductCart
									name='Wardrobes'
									prodCode='MWW (6x3) 002'
									productId={MM_IMAGE_HM_WRDRBS_2}
									dimentions={'H 76" x W 36" x D 19"'}
								>
									<img src={MM_IMAGE_HM_WRDRBS_2} alt='Wardrobes' />
								</ProductCart>
								<ProductCart
									name='Wardrobes'
									prodCode='MWW (6x4) 003'
									productId={MM_IMAGE_HM_WRDRBS_3}
									dimentions={'H 76" x W 48" x D 19"'}
								>
									<img src={MM_IMAGE_HM_WRDRBS_3} alt='Wardrobes' />
								</ProductCart>
								<ProductCart
									name='Wardrobes'
									prodCode='MWW (6x4) 004'
									productId={MM_IMAGE_HM_WRDRBS_4}
									dimentions={'H 79" x W 48" x D 24"'}
								>
									<img src={MM_IMAGE_HM_WRDRBS_4} alt='Wardrobes' />
								</ProductCart>
								<ProductCart
									name='Iron Cupboards'
									prodCode='MWIC 001'
									productId={MM_IMAGE_HM_IRN_CPBD_1}
									dimentions={'H 48" x W 14" x D 32"'}
								>
									<img src={MM_IMAGE_HM_IRN_CPBD_1} alt='Iron Cupboards' />
								</ProductCart>
								<ProductCart
									name='Iron Cupboards'
									prodCode='MWIC 002'
									productId={MM_IMAGE_HM_IRN_CPBD_2}
									dimentions={'H 48" x W 16" x D 32"'}
								>
									<img src={MM_IMAGE_HM_IRN_CPBD_2} alt='Iron Cupboards' />
								</ProductCart>
							</>
						}
						{(category === 'steel office' || category === 'office' || category === 'all') &&
							<><ProductCart
								name='2 Drawer filing cabinet'
								prodCode='MSOF 009'
								productId={MS_IMAGE_OF_2DRWR_FLNG_CBNT_1}
								desc={''}
								dimentions={'H 28" x W 18.5" x D 24"'}
							>
								<img src={MS_IMAGE_OF_2DRWR_FLNG_CBNT_1} alt='2 Drawer filing cabinet' />
							</ProductCart><ProductCart
								name='4 Drawer filing cabinet'
								prodCode='MSOF 011'
								productId={MS_IMAGE_OF_4DRWR_FLNG_CBNT_1}
								desc={''}
								dimentions={'H 53.5" x W 18.5" x D 24"'}
							>
									<img src={MS_IMAGE_OF_4DRWR_FLNG_CBNT_1} alt='4 Drawer filing cabinet' />
								</ProductCart>
								<ProductCart
									name='Office Cupboard'
									prodCode='MSOC 001'
									productId={MS_IMAGE_OF_OFIC_CPBD_1}
									productId2={MS_IMAGE_OF_OFIC_CPBD_1}
									productId3={MS_IMAGE_OF_OFIC_CPBD_2}
									desc={'3 fixed shelves with legs'}
									dimentions={'H 72" x W 36" x D 18"'}
								>
									<img src={MS_IMAGE_OF_OFIC_CPBD_1} alt='Office Cupboard' />
								</ProductCart><ProductCart
									name='Library Cupboard'
									prodCode='MSOC 002'
									productId={MS_IMAGE_OF_LBRY_CPBD_1}
									productId2={MS_IMAGE_OF_LBRY_CPBD_1}
									productId3={MS_IMAGE_OF_LBRY_CPBD_2}
									desc={'4 fixed shelves with legs'}
									dimentions={'H 72" x W 36" x D 18"'}
								>
									<img src={MS_IMAGE_OF_LBRY_CPBD_1} alt='Library Cupboard' />
								</ProductCart><ProductCart
									name='Junior Library Cupboard'
									prodCode='MSOC 003'
									productId={MS_IMAGE_OF_JNR_LBRY_CPBD_1}
									desc={'2 fixed shelves with legs'}
									dimentions={'H 45" x W 35" x D 17"'}
								>
									<img src={MS_IMAGE_OF_JNR_LBRY_CPBD_1} alt='Junior Library Cupboard' />
								</ProductCart>
								<ProductCart
									name='Small Book Rack'
									prodCode='MSOR 007'
									productId={MS_IMAGE_OF_SML_BOOK_RCK_1}
									productId2={MS_IMAGE_OF_SML_BOOK_RCK_1}
									productId3={MS_IMAGE_OF_SML_BOOK_RCK_2}
									desc={'3 fixed shelves, 2 cupboards'}
									dimentions={'H 60" x W 30" x D 12"'}
								>
									<img src={MS_IMAGE_OF_SML_BOOK_RCK_1} alt='Small Book Rack' />
								</ProductCart>
								<ProductCart
									name='Large Book Rack'
									prodCode='MSOR 008'
									productId={MS_IMAGE_OF_LRG_BOOK_RCK_1}
									productId2={MS_IMAGE_OF_LRG_BOOK_RCK_1}
									productId3={MS_IMAGE_OF_LRG_BOOK_RCK_2}
									desc={'3 fixed shelves, 2 cupboards'}
									dimentions={'H 72" x W 36" x D 12"'}
								>
									<img src={MS_IMAGE_OF_LRG_BOOK_RCK_1} alt='Large Book Rack' />
								</ProductCart>
								<ProductCart
									name='Cashier Table'
									prodCode='MSTB 002'
									productId={MS_IMAGE_OF_CSHR_TBL}
									desc={'1 drawer, 1 cupboard, central drawer, fully covered'}
									dimentions={'H 36" x W 24" x D 30"'}
								>
									<img src={MS_IMAGE_OF_CSHR_TBL} alt='Cashier Table' />
								</ProductCart>
								<ProductCart
									name='Typist Table'
									prodCode='MSTB 003'
									productId={MS_IMAGE_OF_TYPST_TBL}
									desc={'Only 2 drawers, fully covered'}
									dimentions={'H 42" x W 24" x D 30"'}
								>
									<img src={MS_IMAGE_OF_TYPST_TBL} alt='Typist Table' />
								</ProductCart>
								<ProductCart
									name='Clerical Table'
									prodCode='MSTB 004'
									productId={MS_IMAGE_OF_CLRCL_TBL}
									desc={'2 drawers, 1 cupboard, fully covered'}
									dimentions={'H 48" x W 30" x D 30"'}
								>
									<img src={MS_IMAGE_OF_CLRCL_TBL} alt='Clerical Table' />
								</ProductCart>
								<ProductCart
									name='Junior Executive Table'
									prodCode='MSTB 005'
									productId={MS_IMAGE_OF_JNR_EXCTV_TBL}
									desc={'3 drawers, 1 cupboard, fully covered'}
									dimentions={'H 48" x W 30" x D 30"'}
								>
									<img src={MS_IMAGE_OF_JNR_EXCTV_TBL} alt='Junior Executive Table' />
								</ProductCart>
								<ProductCart
									name='Workmen locker (15 units)'
									prodCode='MSOW 012'
									productId={MS_IMAGE_OF_WRKMN_LCKR_1}
									productId2={MS_IMAGE_OF_WRKMN_LCKR_1}
									productId3={MS_IMAGE_OF_WRKMN_LCKR_2}
									desc={'1 unit-H 12” x 10”x D 17”'}
									dimentions={'H 65.3" x W 33.75" x D 17"'}
								>
									<img src={MS_IMAGE_OF_WRKMN_LCKR_1} alt='Workmen locker (15 units)' />
								</ProductCart>
								<ProductCart
									name='Steel Cash Box'
									prodCode={'MPCOB 026'}
									productId={IMAGE_CASH_BOX_1}
									productId2={IMAGE_CASH_BOX_2}
									productId3={IMAGE_CASH_BOX_3}
									dimentions={'L 12” W 07” H 05”'}
								>
									<img src={IMAGE_CASH_BOX_1} alt='' />
								</ProductCart>
							</>
						}
						{(category === 'steel home' || category === 'home' || category === 'all') &&
							<>
								<ProductCart
									name='Three Doors Wardrobe'
									prodCode='MSWR 001'
									productId={MS_IMAGE_OF_3_DRS_WRDB_1}
									productId2={MS_IMAGE_OF_3_DRS_WRDB_1}
									productId3={MS_IMAGE_OF_3_DRS_WRDB_2}
									desc={'1 Locker, 1 Drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 60" x D 17"'}
								>
									<img src={MS_IMAGE_OF_3_DRS_WRDB_1} alt='Three Doors Wardrobe' />
								</ProductCart><ProductCart
									name='Dressing Table Wardrobe'
									prodCode='MSWR 002'
									productId={MS_IMAGE_OF_DRSNG_TBL_WRDB_1}
									productId2={MS_IMAGE_OF_DRSNG_TBL_WRDB_1}
									productId3={MS_IMAGE_OF_DRSNG_TBL_WRDB_2}
									desc={'1 Locker, 1 Drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 60" x D 17"'}
								>
									<img src={MS_IMAGE_OF_DRSNG_TBL_WRDB_1} alt='Dressing Table Wardrobe' />
								</ProductCart><ProductCart
									name='Double Lock Std Wardrobe'
									prodCode='MSWR 003'
									productId={MS_IMAGE_OF_DBL_LCK_STD_WRDB_1}
									productId2={MS_IMAGE_OF_DBL_LCK_STD_WRDB_3}
									productId3={MS_IMAGE_OF_DBL_LCK_STD_WRDB_2}
									desc={'Double-side drawers, with mirror'}
									dimentions={'H 75" x W 47" x D 18"'}
								>
									<img src={MS_IMAGE_OF_DBL_LCK_STD_WRDB_1} alt='Double Lock Std Wardrobe' />
								</ProductCart><ProductCart
									name='STD Mini Wardrobe'
									prodCode='MSWR 004'
									productId={MS_IMAGE_OF_STD_MINI_WRDB_3}
									productId2={MS_IMAGE_OF_STD_MINI_WRDB_2}
									productId3={MS_IMAGE_OF_STD_MINI_WRDB_1}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 42" x D 17"'}
								>
									<img src={MS_IMAGE_OF_STD_MINI_WRDB_3} alt='STD Mini Wardrobe' />
								</ProductCart><ProductCart
									name='Delux STD Wardrobe'
									prodCode='MSWR 005'
									productId={MS_IMAGE_OF_DLX_STD_WRDB_1}
									productId2={MS_IMAGE_OF_DLX_STD_WRDB_1}
									productId3={MS_IMAGE_OF_DLX_STD_WRDB_2}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 47" x D 20"'}
								>
									<img src={MS_IMAGE_OF_DLX_STD_WRDB_1} alt='Delux STD Wardrobe' />
								</ProductCart><ProductCart
									name='Junior STD Wardrobe'
									prodCode='MSWR 006'
									productId={MS_IMAGE_OF_JNR_STD_WRDB_1}
									productId2={MS_IMAGE_OF_JNR_STD_WRDB_1}
									productId3={MS_IMAGE_OF_JNR_STD_WRDB_2}
									desc={'1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 47" x D 18"'}
								>
									<img src={MS_IMAGE_OF_JNR_STD_WRDB_1} alt='Junior STD Wardrobe' />
								</ProductCart><ProductCart
									name='Economy Wardrobe'
									prodCode='MSWR 007'
									productId={MS_IMAGE_OF_ECNMY_WRDB_1}
									productId2={MS_IMAGE_OF_ECNMY_WRDB_2}
									productId3={MS_IMAGE_OF_ECNMY_WRDB_3}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 35" x D 17"'}
								>
									<img src={MS_IMAGE_OF_ECNMY_WRDB_4} alt='Economy Wardrobe' />
								</ProductCart><ProductCart
									name='Std Silver Wardrobe'
									prodCode='MSWR 011'
									productId={MS_IMAGE_OF_STD_SLVR_WRDB_1}
									productId2={MS_IMAGE_OF_STD_SLVR_WRDB_1}
									productId3={MS_IMAGE_OF_STD_SLVR_WRDB_2}
									desc={'1 locker, coat hanger with mirror'}
									dimentions={'H 72" x W 45" x D 17"'}
								>
									<img src={MS_IMAGE_OF_STD_SLVR_WRDB_1} alt='Std Silver Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Medium Cupboard (W/O Drawer)'
									prodCode='MSOC 004'
									productId={MS_IMAGE_OF_MDM_CPBD_WOD_1}
									productId2={MS_IMAGE_OF_MDM_CPBD_WOD_2}
									productId3={MS_IMAGE_OF_MDM_CPBD_WOD_3}
									desc={'2 fixed shelves with legs'}
									dimentions={'H 52" x W 36" x D 16"'}
								>
									<img src={MS_IMAGE_OF_MDM_CPBD_WOD_1} alt='Medium Cupboard (W/O Drawer)' />
								</ProductCart>
								<ProductCart
									name='Medium Cupboard (1 Drawer)'
									prodCode='MSOC 005'
									productId={MS_IMAGE_OF_MDM_CPBD_1D_1}
									productId2={MS_IMAGE_OF_MDM_CPBD_1D_1}
									productId3={MS_IMAGE_OF_MDM_CPBD_1D_2}
									desc={'2 fixed shelves with legs'}
									dimentions={'H 52" x W 36" x D 16"'}
								>
									<img src={MS_IMAGE_OF_MDM_CPBD_1D_1} alt='Medium Cupboard (1 Drawer)' />
								</ProductCart>
								<ProductCart
									name='Medium Cupboard (2 drawers)'
									prodCode='MSOC 006'
									productId={MS_IMAGE_OF_MDM_CPBD_2D_1}
									productId2={MS_IMAGE_OF_MDM_CPBD_2D_1}
									productId3={MS_IMAGE_OF_MDM_CPBD_2D_2}
									desc={'2 fixed shelves with legs'}
									dimentions={'H 52 x W 36 x D 16'}
								>
									<img src={MS_IMAGE_OF_MDM_CPBD_2D_1} alt='Medium Cupboard (2 drawers)' />
								</ProductCart>
								<ProductCart
									name='Gold Three Doors Wardrobe'
									prodCode='MPCWR 013'
									productId={MS_IMAGE_OF_GOLD_3DRS_WRDB}
									desc={'1 Locker, 1 Drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 60" x D 17"'}
								>
									<img src={MS_IMAGE_OF_GOLD_3DRS_WRDB} alt='Gold Three Doors Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Dressing Table Wardrobe'
									prodCode='MPCWR 014'
									productId={MS_IMAGE_OF_GOLD_DRSN_TBL_WRDB}
									desc={'1 Locker, 1 Drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 60" x D 17"'}
								>
									<img src={MS_IMAGE_OF_GOLD_DRSN_TBL_WRDB} alt='Gold Dressing Table Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Double Lock Std Wardrobe'
									prodCode='MPCWR 015'
									productId={MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_1}
									productId3={MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_2}
									desc={'Double-side drawers, with mirror'}
									dimentions={'H 75" x W 47" x D 18"'}
								>
									<img src={MS_IMAGE_OF_GOLD_DBL_LCK_STD_WRDB_1} alt='Gold Double Lock Std Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Std Mini Wardrobe'
									prodCode='MPCWR 016'
									productId={MS_IMAGE_OF_GOLD_STD_MINI_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_STD_MINI_WRDB_2}
									productId3={MS_IMAGE_OF_GOLD_STD_MINI_WRDB_3}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 42" x D 17"'}
								>
									<img src={MS_IMAGE_OF_GOLD_STD_MINI_WRDB_1} alt='Gold Std Mini Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Delux Std Wardrobe'
									prodCode='MPCWR 017'
									productId={MS_IMAGE_OF_GOLD_DLX_STD_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_DLX_STD_WRDB_2}
									productId3={MS_IMAGE_OF_GOLD_DLX_STD_WRDB_3}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 47" x D 20"'}
								>
									<img src={MS_IMAGE_OF_GOLD_DLX_STD_WRDB_1} alt='Gold Delux Std Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Junior Std Wardrobe'
									prodCode='MPCWR 018'
									productId={MS_IMAGE_OF_GOLD_JNR_STD_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_JNR_STD_WRDB_2}
									productId3={MS_IMAGE_OF_GOLD_JNR_STD_WRDB_3}
									desc={'1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 47" x D 18"'}
								>
									<img src={MS_IMAGE_OF_GOLD_JNR_STD_WRDB_1} alt='Gold Junior Std Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Economy Wardrobe'
									prodCode='MPCWR 019'
									productId={MS_IMAGE_OF_GOLD_ECNMY_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_ECNMY_WRDB_2}
									productId3={MS_IMAGE_OF_GOLD_ECNMY_WRDB_3}
									desc={'Locker with secret drawer, 1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 35" x D 17"'}
								>
									<img src={MS_IMAGE_OF_GOLD_ECNMY_WRDB_1} alt='Gold Economy Wardrobe' />
								</ProductCart>
								<ProductCart
									name='Gold Popular Wardrobe'
									prodCode='MPCWR 020'
									productId={MS_IMAGE_OF_GOLD_PPLR_WRDB_1}
									productId2={MS_IMAGE_OF_GOLD_PPLR_WRDB_1}
									productId3={MS_IMAGE_OF_GOLD_PPLR_WRDB_2}
									desc={'1 drawer, coat hanger, saree rail, with mirror'}
									dimentions={'H 75" x W 35" x D 17"'}
								>
									<img src={MS_IMAGE_OF_GOLD_PPLR_WRDB_1} alt='Gold Popular Wardrobe' />
								</ProductCart>
							</>
						}
					</div>
				</div>
			</div>

			{/* <div className='products-section-two'>
				<div className='container'>
					<h2>Find your nearest dealer now</h2>
					<div className='dealer-logo-container'>
						<div className='img-wrapper'>
							<img src={LOGO_ELEGANT} alt='' />
							<img src={LOGO_SINGER} alt='' />
							<img src={LOGO_FURNITURE} alt='' />
							<img src={LOGO_ELEGANT} alt='' />
							<img src={LOGO_SINGER} alt='' />
							<img src={LOGO_FURNITURE} alt='' />
							<img src={LOGO_ELEGANT} alt='' />
							<img src={LOGO_SINGER} alt='' /> 
						</div>
					</div>
					<button className='btn-secondary-two'>
						Find Dealers
						<Link to='/dealers'>Find Dealers</Link>
					</button>
				</div>
			</div> */}

			{/* <div className='products-section-three'>
				<div className='container'>
					<div className='row'>
						<ProductCart>
							<img src={IMAGE_PRODUCT_THREE} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_ONE} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_TWO} alt='' />
						</ProductCart>
					</div>
				</div>
			</div> */}

			<div className='products-section-custom-product'>
				<CustomProduct />
			</div>

			{/* <div className='products-section-four'>
				<div className='container'>
					<div className='row'>
						<ProductCart>
							<img src={IMAGE_PRODUCT_FOUR} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_FIVE} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_SIX} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_THREE} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_ONE} alt='' />
						</ProductCart>
						<ProductCart>
							<img src={IMAGE_PRODUCT_TWO} alt='' />
						</ProductCart>
					</div>
				</div>
			</div> */}

			<Footer></Footer>
		</div>
	);
}
