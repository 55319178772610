import { HashLink } from 'react-router-hash-link';

import './CustomProduct.scss';

//Assets
import BG_CUSTOM_PRODUCT from './../../assets/images/img-bg-custom-product.png';

export default function CustomProduct({ marginT = '0px' }) {
	// Custom Product background style
	const customBg = {
		backgroundImage: `url(${BG_CUSTOM_PRODUCT})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
	};

	const customStyles = {
		marginTop: `${marginT}`,
	};

	return (
		<div
			className='custome-pro-wrapper'
			style={{ ...customBg, ...customStyles }}
		>
			<div className='container'>
				<div className='content-wrapper'>
					<h2 data-aos="fade-right">
						Unleash your design potential: Let Milro bring your furniture dreams to life.
					</h2>
					<button className='btn-secondary-two' data-aos="fade-right">
						<HashLink smooth to='/custom-product#'>
							Build a custom product
						</HashLink>
					</button>
				</div>
			</div>
		</div>
	);
}
