// Styles
import './ContactUs.scss';

// Components
import Header from './../../components/header/Header';
import Hero from './../../components/hero/Hero';
import NavBar from './../../components/navbar/NavBar';
import Footer from './../../components/footer/Footer';
import CustomProduct from './../../components/custom-product/CustomProduct';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';

// Assets
import IMAGE_HEADER from './../../assets/images/contact-us/img-header.png';

export default function ContactUs() {
	return (
		<div>
			<NavBar></NavBar>
			<Header bgImage={IMAGE_HEADER}>
				<Hero>
					<h1>
						Contact <br /> Us
					</h1>
				</Hero>
			</Header>

			<div className='contact-us-section-one'>
				<div className='container'>
					<h2>We are here to help you</h2>
					<div className='row'>
						<div className='col-md-4 cl'>
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='40'
									height='40'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M13.3334 38.0969C8.39641 40.2748 5.33337 43.3097 5.33337 46.6666C5.33337 53.294 17.2724 58.6666 32 58.6666C46.7276 58.6666 58.6667 53.294 58.6667 46.6666C58.6667 43.3097 55.6037 40.2748 50.6667 38.0969M48 21.3333C48 32.1698 36 37.3333 32 45.3333C28 37.3333 16 32.1698 16 21.3333C16 12.4967 23.1635 5.33325 32 5.33325C40.8366 5.33325 48 12.4967 48 21.3333ZM34.6667 21.3333C34.6667 22.806 33.4728 23.9999 32 23.9999C30.5273 23.9999 29.3334 22.806 29.3334 21.3333C29.3334 19.8605 30.5273 18.6666 32 18.6666C33.4728 18.6666 34.6667 19.8605 34.6667 21.3333Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Main Office</h6>
							<p>
							1551/15,<br /> Amuetamulla road, kottawa, <br /> pannipitiya.
							</p>
						</div>
						<div className='col-md-4 cl'>
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='40'
									height='40'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M37.4658 15.9999C40.0704 16.5081 42.4642 17.7819 44.3406 19.6584C46.2171 21.5349 47.491 23.9286 47.9991 26.5333M37.4658 5.33325C42.8772 5.93442 47.9234 8.35772 51.7758 12.2053C55.6282 16.0528 58.0578 21.0959 58.6658 26.5066M27.2711 36.9681C24.0669 33.7639 21.5368 30.1408 19.6808 26.2752C19.5211 25.9427 19.4413 25.7764 19.38 25.5661C19.1621 24.8185 19.3186 23.9004 19.772 23.2673C19.8995 23.0891 20.0519 22.9367 20.3568 22.6319C21.2891 21.6996 21.7552 21.2334 22.06 20.7647C23.2093 18.997 23.2093 16.7181 22.06 14.9504C21.7552 14.4817 21.2891 14.0155 20.3568 13.0832L19.8371 12.5636C18.4199 11.1464 17.7114 10.4378 16.9503 10.0529C15.4369 9.28738 13.6495 9.28738 12.136 10.0529C11.375 10.4378 10.6664 11.1464 9.24919 12.5636L8.82883 12.9839C7.41651 14.3963 6.71034 15.1024 6.17101 16.0625C5.57256 17.1279 5.14226 18.7825 5.1459 20.0044C5.14917 21.1056 5.36278 21.8582 5.79 23.3634C8.08592 31.4525 12.4179 39.0854 18.7858 45.4534C25.1537 51.8213 32.7867 56.1533 40.8758 58.4492C42.381 58.8764 43.1336 59.09 44.2347 59.0933C45.4567 59.0969 47.1113 58.6666 48.1767 58.0682C49.1367 57.5288 49.8429 56.8227 51.2552 55.4104L51.6756 54.99C53.0928 53.5728 53.8014 52.8642 54.1863 52.1032C54.9518 50.5897 54.9518 48.8023 54.1863 47.2888C53.8014 46.5278 53.0928 45.8192 51.6756 44.4021L51.156 43.8824C50.2237 42.9501 49.7575 42.484 49.2888 42.1792C47.5211 41.0299 45.2422 41.0299 43.4745 42.1792C43.0057 42.484 42.5396 42.9501 41.6073 43.8824C41.3025 44.1872 41.1501 44.3397 40.9719 44.4672C40.3388 44.9206 39.4207 45.0771 38.6731 44.8592C38.4627 44.7979 38.2965 44.7181 37.964 44.5584C34.0983 42.7024 30.4753 40.1723 27.2711 36.9681Z'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Weekday 8am - 5pm</h6>
							<p>
								+94 11 30 24 001
								{/* <br /> +94 0113024001 */}
							</p>
						</div>
						<div className='col-md-4 cl'>
							<div className='img-wrapper'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='40'
									height='40'
									viewBox='0 0 64 64'
									fill='none'
								>
									<path
										d='M36.6506 7.0228L56.7253 20.0714C57.4347 20.5324 57.7893 20.763 58.0463 21.0705C58.2738 21.3427 58.4447 21.6576 58.5489 21.9966C58.6667 22.3797 58.6667 22.8027 58.6667 23.6487V43.1999C58.6667 47.6803 58.6667 49.9206 57.7948 51.6318C57.0278 53.1371 55.8039 54.361 54.2986 55.128C52.5873 55.9999 50.3471 55.9999 45.8667 55.9999H18.1334C13.653 55.9999 11.4127 55.9999 9.70145 55.128C8.19615 54.361 6.97231 53.1371 6.20532 51.6318C5.33337 49.9206 5.33337 47.6803 5.33337 43.1999V23.6487C5.33337 22.8027 5.33337 22.3797 5.45116 21.9966C5.55543 21.6576 5.7263 21.3427 5.95377 21.0705C6.21074 20.763 6.56541 20.5324 7.27476 20.0714L27.3495 7.0228M36.6506 7.0228C34.9673 5.92864 34.1256 5.38156 33.2188 5.1687C32.4172 4.98055 31.5829 4.98055 30.7813 5.1687C29.8744 5.38156 29.0328 5.92864 27.3495 7.0228M36.6506 7.0228L53.1631 17.7559C54.9973 18.9482 55.9145 19.5443 56.2321 20.3003C56.5097 20.961 56.5097 21.7056 56.2321 22.3662C55.9145 23.1222 54.9973 23.7184 53.1631 24.9106L36.6506 35.6437C34.9673 36.7379 34.1256 37.285 33.2188 37.4978C32.4172 37.686 31.5829 37.686 30.7813 37.4978C29.8744 37.285 29.0328 36.7379 27.3495 35.6437L10.837 24.9106C9.00274 23.7184 8.08561 23.1222 7.76797 22.3662C7.49038 21.7056 7.49038 20.961 7.76797 20.3003C8.08561 19.5443 9.00274 18.9482 10.837 17.7559L27.3495 7.0228M57.3334 50.6666L39.6192 34.6666M24.3809 34.6666L6.66671 50.6666'
										stroke='#1D2670'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<h6>Say hello</h6>
							<p>info@milroindustries.com</p>
						</div>
					</div>
				</div>
			</div>

			<div className='contact-us-section-form'>
				<ContactUsForm />
			</div>

			<div className='contact-us-section-custom-product'>
				<CustomProduct />
			</div>
			<Footer></Footer>
		</div>
	);
}
