// Styles
import './Dealers.scss';

// Components
import NavBar from '../../components/navbar/NavBar';
import Header from './../../components/header/Header';
import Hero from '../../components/hero/Hero';
import CustomProduct from '../../components/custom-product/CustomProduct';
import Footer from './../../components/footer/Footer';

// Assets
import IMAGE_HEADER from './../../assets/images/dealers/img-header.png';
import IMAGE_DEALER_ONE from './../../assets/images/dealers/img-dealer-1.png';
import IMAGE_DEALER_TWO from './../../assets/images/dealers/img-dealer-2.png';

export default function Dealers() {
	return (
		<div>
			<NavBar></NavBar>
			<Header bgImage={IMAGE_HEADER}>
				<Hero>
					<h1>
						600+ <br /> Dealers
					</h1>
				</Hero>
			</Header>

			<div className='dealers-section-one'>
				<div className='container'>
					<div className='btn-wrapper'>
						<p>District</p>
						<button className='btn-secondary-two'>
							Select your district
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 32 32'
								fill='none'
							>
								<path
									d='M8 12L16 20L24 12'
									stroke='#1D2670'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					</div>

					<div className='btn-wrapper'>
						<p>City</p>
						<button className='btn-secondary-two'>
							Select your city
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 32 32'
								fill='none'
							>
								<path
									d='M8 12L16 20L24 12'
									stroke='#1D2670'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</button>
					</div>

					<div className='dealers-container'>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_TWO} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
					</div>
				</div>
			</div>

			<div className='dealers-section-custom-product'>
				<CustomProduct />
			</div>

			<div className='dealers-section-one'>
				<div className='container'>
					<div className='dealers-container'>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_TWO} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
						<div className='wrapper'>
							<div className='logo-wrapper'>
								<img src={IMAGE_DEALER_ONE} alt='' />
							</div>
							<h6>Damro</h6>
							<p>Tel: 091 225 4678</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
