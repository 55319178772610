import './Partners.scss';
import { Link } from 'react-router-dom';

// Assets
// import LOGO_ELEGANT from './../../assets/images/partners-logo/logo-elegent.png';
import LOGO_SINGER from './../../assets/images/partners-logo/logo-singer.png';
import LOGO_FURNITURE from './../../assets/images/partners-logo/logo-furniture.png';

export default function Partners() {
	return (
		<div className='container partners'>
			<div className='row'>
				<div className='col-md-6 col-first'>
					<h2>Our partners</h2>
					<p>
						Milro Industries enjoys a strong and supportive network of over 600 valued partners across Sri Lanka.
					</p>
				</div>
				<div className='col-md-6 col-two'>
					<div className='partners-projects-wrapper'>
						<h2>
							600<span className='pluss'>+</span>
							<span className='partners'>Partners</span>
						</h2>
						<h2>
							1500<span className='pluss'>+</span>
							<span className='projects'>projects</span>
						</h2>
					</div>
				</div>
			</div>
			<div className='partners-logo-container'>
				<div className='img-wrapper'>
					{/* <img src={LOGO_ELEGANT} alt='' /> */}
					<img src={LOGO_SINGER} alt='' />
					<img src={LOGO_FURNITURE} alt='' />
					{/* <img src={LOGO_ELEGANT} alt='' />
					<img src={LOGO_SINGER} alt='' />
					<img src={LOGO_FURNITURE} alt='' />
					<img src={LOGO_ELEGANT} alt='' />
					<img src={LOGO_SINGER} alt='' /> */}
				</div>
			</div>
			<button className='btn-secondary-two'>
				<Link to='/contact-us'>Be a partner</Link></button>
		</div>
	);
}
