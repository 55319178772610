import './Header.scss';

export default function Header({ children, height = '400px', bgImage }) {
	// Header style
	const headerStyle = {
		width: '100%',
		height: `${height}`,
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center center',
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		paddingTop: '10px',
	};

	return (
		<div className='header' style={headerStyle}>
			{children}
		</div>
	);
}
