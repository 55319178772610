import { NavHashLink } from 'react-router-hash-link';
import { useState } from 'react';

// NavBar styles
import './NavBar.scss';

// Assets
import LOGO_BLUE from './../../assets/logos/Milro-Industries-(PVT)-Ltd-BLUE.png';
import LOGO_COLOR from './../../assets/logos/Milro-Industries-(PVT)-Ltd-COLOR.png';
import LOGO_WHITE from './../../assets/logos/Milro-Industries-(PVT)-Ltd-WHITE.png';
import { Link } from 'react-router-dom';

export default function NavBar() {
	// useState hook
	const [navActive, setNavActive] = useState(false);
	const [navBtnActive, setNavBtnActive] = useState(true);

	// Handler functions
	function openNav() {
		setNavActive(true);
		setNavBtnActive(false);
	}

	function clossNav() {
		setNavActive(false);
		setNavBtnActive(true);
	}

	return (
		<div>
			<div className={`mobile-nav-open-wrapper ${!navBtnActive && 'hide'}`}>
				<Link to={'/'}>
					<img className='logo-mobile-nav' src={LOGO_WHITE} alt='' />
				</Link>

				<svg
					className='mobile-nav-open'
					xmlns='http://www.w3.org/2000/svg'
					width='35.853'
					height='29'
					viewBox='0 0 18.853 12'
					onClick={openNav}
				>
					<g
						id='Icon_feather-menu'
						data-name='Icon feather-menu'
						transform='translate(-4.5 -8)'
					>
						<path
							id='Path_3'
							data-name='Path 3'
							d='M4.5,18H23.353'
							transform='translate(0 -4)'
							fill='none'
							stroke='#fff'
							stroke-linejoin='round'
							stroke-width='2'
						/>
						<path
							id='Path_4'
							data-name='Path 4'
							d='M4.5,9H23.353'
							transform='translate(0)'
							fill='none'
							stroke='#fff'
							stroke-linejoin='round'
							stroke-width='2'
						/>
						<path
							id='Path_5'
							data-name='Path 5'
							d='M4.5,27H23.353'
							transform='translate(0 -8)'
							fill='none'
							stroke='#fff'
							stroke-linejoin='round'
							stroke-width='2'
						/>
					</g>
				</svg>
			</div>

			<svg
				className={`mobile-nav-closs ${navBtnActive && 'hide'}`}
				xmlns='http://www.w3.org/2000/svg'
				width='30.953'
				height='30.953'
				viewBox='0 0 20.953 20.953'
				onClick={clossNav}
			>
				<path
					id='Icon_ionic-md-close'
					data-name='Icon ionic-md-close'
					d='M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z'
					transform='translate(-7.523 -7.523)'
					fill='#FFFFFF'
				/>
			</svg>

			<nav className={`nav-bar ${!navActive && 'hide'}`}>
				<div className='container'>
					<img className='logo' src={LOGO_WHITE} alt='' />

					<li>
						<NavHashLink to='/'>Home</NavHashLink>
					</li>
					<li>
						<NavHashLink to='/products'>Products</NavHashLink>
					</li>
					<li>
						<NavHashLink to='/custom-product'>Custom Products</NavHashLink>
					</li>
					{/* <li>
						<NavHashLink to='/dealers'>Dealers</NavHashLink>
					</li> */}
					<li>
						<NavHashLink to='/about-us'>About us</NavHashLink>
					</li>
					<li>
						<NavHashLink to='/contact-us'>Contact</NavHashLink>
					</li>
				</div>
			</nav>
		</div>
	);
}
